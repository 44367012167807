@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

@font-face {
  font-family: "NeueHaasUnicaW1G-Regular";
  src: url("./assets/fonts/NeueHaasUnicaW1G/31267e36af6f8dac65a56e78345e945e.eot"); /* IE9*/
  src: url("./assets/fonts/NeueHaasUnicaW1G/31267e36af6f8dac65a56e78345e945e.eot")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./assets/fonts/NeueHaasUnicaW1G/31267e36af6f8dac65a56e78345e945e.woff2")
      format("woff2"),
    /* chrome firefox */
      url("./assets/fonts/NeueHaasUnicaW1G/31267e36af6f8dac65a56e78345e945e.woff")
      format("woff"),
    /* chrome firefox */
      url("./assets/fonts/NeueHaasUnicaW1G/31267e36af6f8dac65a56e78345e945e.ttf")
      format("truetype"),
    /* chrome firefox opera Safari, Android, iOS 4.2+*/
      url("./assets/fonts/NeueHaasUnicaW1G/31267e36af6f8dac65a56e78345e945e.svg")
      format("svg"); /* iOS 4.1- */
}

.pause {
  animation-play-state: paused;
}

.play {
  animation-play-state: running !important;
}

.noise {
  background-image: url("./assets/noise.png");
  box-shadow: 0 0 50px 50px black inset;
}

html,
body,
#root,
#root < div {
  height: 100%;
}

/* #root < div {
  height: 100%;
} */

@media only screen and (max-width: 600px) {
  #root < div {
    height: 100%;
  }
  html {
    height: -webkit-fill-available;
  }

  #mainContainer {
    height: -webkit-fill-available;
  }
}
